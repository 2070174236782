$image-basic-path: '../static/images/homepage';

.home-banner {
  display: flex;
  position: relative;
  align-items: center;
  height: 700px;
  background-color: #00112D;
  flex-direction: column;
  background-image: url('#{$image-basic-path}/banner-bg.png');
  background-size: cover;
  background-position: center;
  padding-bottom: 100px;
  
  #weixinGuide {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background: rgba(0,0,0,.6);

    .content {
      position: absolute;
      top: 0.17rem;
      right: 0.23rem;
      width: 3.03rem;
      height: 3.40rem;
      background-image: url('#{$image-basic-path}/weixin-guide.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__hd {
    height: 50px;
    display: flex;
    align-items: center;

    .brand {
      height: 28px;
    }

    .lang-selector {
      position: absolute;
      left: 50%;
      margin-left: 500px;
      width: 80px;
      z-index: 100;
      .current {
        font-size: 12px;
        color: #67B6FF;
        text-decoration: none;
      }
      .icon {
        height: 6px;
        margin-left: 4px;
      }
      .dropdown {
        // display: block;
        position: absolute;
        top: 30px;
        z-index: 99;
        a {
          font-size: 12px;
          color: #67B6FF;
          cursor: pointer;
          flex: 1;
          &:hover {
            opacity: .9;
          }
        }
      }
    }
  }

  &__bd {
    display: flex;
    flex: 1;
    position: relative;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 550px;
    height: 430px;
    box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.16);
    border-radius: 64px;
    background: linear-gradient(45deg,rgba(0,157,230,1) 0%,rgba(0,27,67,1) 100%);
    position: relative;
    overflow: hidden;

    .mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,16,44,1);
    }

    &.swiper-slide-active {
      opacity: 1;
    }

    .inner {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px;
      position: relative;
      z-index: 10;
      .product {
        width: 270px;
        height: 260px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        &.cubestation {
          background-image: url('#{$image-basic-path}/banner-app.png');
        }
        &.gan356i {
          background-image: url('#{$image-basic-path}/banner-cube.png');
        }
        &.gan-robot {
          background-image: url('#{$image-basic-path}/banner-robot.png');
        }
      }

      .name {
        color: #fff;
        font-size: 46px;
        position: relative;
        padding-bottom: 30px;
        flex: 1;
        text-align: center;

        &.name__cubestation {
          font-size: 36px;
        }

        .app-sign {
          display: block;
          margin-bottom: 20px;
          font-size: 44px;
          color: #67B6FF;
        }

        &:after {
          content: '';
          background-color: #67B6FF;
          width: 50px;
          height: 6px;
          position: absolute;
          bottom: 0;
          display: block;
          left: 50%;
          margin-left: -25px;
        }
      }
    }
  }

  .swiper-container {
    width: 1920px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
  }
  .img-wrapper {
    img {
      width: 80%;
    }
  }
  .swiper-pagination {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    .swiper-pagination-bullet {
      margin: 0 5px;
      width: 30px;
      border-radius: 4px;
    }
  }

  .swiper-button-wrapper {
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }

  .swiper-button {
    width: 86px;
    height: 86px;
    background-image: url('#{$image-basic-path}/page-controller.svg');
    background-size: 100% 100%;

    &.swiper-button-prev {

    }

    &.swiper-button-next {
      transform: rotate(180deg);
    }

    &:focus {
      outline: none;
    }
  }

  &__ft {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100px;
    background-color: rgba(0,17,45,.6);
    // background-color: #ccc;

    &.fixed {
      position: fixed;
    }

    .ft-container {
      width: 1100px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .slogan {
      height: 50px;
      width: auto;
    }

    .link {
      display: flex;
    }

    .link-pc {
      .link-btn {
        display: block;
        color: #fff;
        font-size: 0.3rem;
        width: 150px;
        line-height: 50px;
        line-height: 50px;
        font-size: 16px;
        border-radius: 25px;
        text-align: center;
        background: linear-gradient(to bottom, #FCD482, #F74732);
  
        &:hover {
          opacity: .8;
        }

        &:active {
          opacity: .8;
        }
      }
    }

    .link-mb {
      display: none;
    }

    .link-box {
      border: solid 1px #808993;
      border-radius: 4px;
      padding: 10px 15px;
      height: 60px;
      margin-right: 10px;
      text-decoration: none;
      &:nth-last-child(1) {
        margin-right: 0;
      }
      img {
        height: 100%;
      }
    }

    .qrcode-link {
      position: relative;
      &:hover {
        .qrcode-wrapper {
          display: block;
        }
      }

      .qrcode-wrapper {
        width: 150px;
        height: 150px;
        background-color: #fff;
        position: absolute;
        top: -150px - 20px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 4px;
        display: none;
        padding: 5px;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          border-bottom: 15px solid transparent;
          border-right: 20px solid transparent;
          border-left: 20px solid transparent;
          border-top: 15px solid #fff;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .app-link {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #fff;
      &:hover {
        opacity: .8;
      }
      img {
        height: 40px;
        width: auto;
        margin-right: 18px;
      }
    }
  }
}

.mb-header {
  display: none;
}

@media (max-width: 768px) {
  .home-banner {
    height: 7rem;
    background-image: url('#{$image-basic-path}/mb-banner-bg.png');
    background-size: cover;
    padding-bottom: 1rem;
    &__hd {
      padding-top: 0.3rem;
      .brand {
        height: .4rem;
      }
      .lang-selector {
        margin-left: auto;
        left: auto;
        right: 0.3rem;
        font-size: 0.22rem;
      }
    }

    .swiper-container {
      width: 15.6rem;
    }

    .swiper-slide {
      height: 3.58rem;
      border-radius: 0.52rem;

      .inner {
        padding: 0 0.2rem;
        .name {
          font-size: 0.4rem;

          &:after {
            height: 0.06rem;
            width: 0.5rem;
            margin-left: -0.25rem;
          }

          &.name__cubestation {
            font-size: 0.34rem;
          }

          .app-sign {
            font-size: 0.4rem;
          }
        }

        .product {
          width: 2.4rem;
          height: 3rem;
        }
      }
    }

    .swiper-button-wrapper {
      width: 100vw;
    }

    .swiper-button {
      width: 0.86rem;
      height: 0.86rem;
    }

    .swiper-button-next {
      right: 0.14rem;
    }
    
    .swiper-button-prev {
      left: 0.14rem;
    }

    &__ft {
      height: 1rem;

      .ft-container {
        width: 100vw;
        padding: 0 0.32rem;
      }

      .slogan {
        height: 0.5rem;
      }

      .link {
        display: none;
      }
      .link-pc {
        display: none;
      }
      .link-mb {
        display: block;
        .link-btn {
          display: block;
          color: #fff;
          font-size: 0.3rem;
          line-height: 0.68rem;
          width: 2.2rem;
          border-radius: 0.34rem;
          text-align: center;
          background: linear-gradient(to bottom, #FCD482, #F74732);
          &.download {
            background: linear-gradient(to bottom, #67b6ff, #0095e3);
          }
  
          &:active {
            opacity: .8;
          }
        }
      }
    }
  }

  .mb-header {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 0.88rem;
    z-index: 99;
    background-color: #fff;
    opacity: 0;
    transition: opacity .3s;

    &.active {
      opacity: 1;
    }

    > a {
      flex: 1;
      text-align: center;
      line-height: 0.88rem;
      font-size: 0.3rem;
      color: #999;
      position: relative;
      text-decoration: none;

      &.active {
        color: #67B6FF;

        &:after {
          content: '';
          display: block;
          width: 0.5rem;
          height: 0.06rem;
          background-color: #67B6FF;
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -0.25rem;
        }
      }
    }
  }
}