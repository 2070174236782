@mixin size($w, $h) {
  width: $w;
  height: $h;
}

@mixin image-url($path) {
  background-image: url('./images/#{$path}');
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

@mixin float($direction) {
  float: $direction;
  *display: inline;
}

@mixin opacity($opacity) {
  filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
  opacity: $opacity;
}

// Make an element completely transparent.
@mixin transparent { @include opacity(0); }

@mixin text-overflow {
  max-width     : 100%;
  overflow      : hidden;
  text-overflow : ellipsis;
  white-space   : nowrap;
}

@mixin word-break {
  word-break  : break-all;
  word-wrap   : break-word;
  white-space : normal;
}

@mixin border-radius($radius) {
  -webkit-border-radius   : $radius;
  -moz-border-radius      : $radius;
  border-radius           : $radius;
}

@mixin gradient($start: #000000, $stop: #FFFFFF) {
  background : ($start + $stop)/2;
  background : -webkit-gradient(linear, left top, left bottom, color-stop(0, $start), color-stop(1, $stop));
  background : -moz-linear-gradient(center top, $start 0%, $stop 100%);
  background : -ms-linear-gradient(top, $start, $stop);
  filter     : unquote("progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start}', endColorstr='#{$stop}', GradientType=0)");
}
