@import 'mixins';
@import 'config';

//includes
@import 'includes/_header';
@import 'includes/_footer';

//pages

// homepage
@import 'pages/homepage/basic';
@import 'pages/homepage/banner';
@import 'pages/homepage/footer';
@import 'pages/homepage/cubestation.scss';

// gan356i
@import 'pages/gan356i/index';

// gan-robot
@import 'pages/gan-robot/index';

// gan-356i-branch2
@import 'pages/gan356i-branch2/index';
