.homepage-footer {
  background-color: #002044;
  padding: 0 0 60px;

  &__hd {
    display: none;
  }

  &__ft {
    padding-top: 40px;

    .link-group {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .title {
        margin-right: 30px;
        font-size: 15px;
        color: #66798E;
      }

      .link-item {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-right: 50px;
        color: inherit;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        img {
          height: 23px;
          width: auto;
          margin-right: 10px;
        }
        .amazon-light {
          display: block;
        }
        .amazon-dark {
          display: none;
        }
      }
    }

    .copyright {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #66798E;
      font-size: 13px;
      margin-top: 50px;
      span:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }

  &.light {
    background-color: #fff;
    .homepage-footer {
      &__ft {
        .link-group {
          color: #333;
          .link-item {
            .amazon-light {
              display: none;
            }
            .amazon-dark {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .homepage-footer {
    padding-top: 0.62rem;
    padding-bottom: 0.62rem;
    .homepage-footer-container {
      width: 6.5rem;
      margin: 0 auto;
    }
    &__hd {
      display: block;
      color: #fff;
      position: relative;
      border-top: solid 1px #243F5E;
      > span {
        color: #66798E;
        background-color: #002044;
        font-size: 0.18rem;
        width: 1.6rem;
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -0.8rem;
        text-align: center;
        top: -0.13rem;      
        line-height: 1em;
      }
    }
    &__ft {
      .link-group {
        .title {
          display: none;
        }
        .link-item {
          margin-right: 0.5rem;
          color: #67B6FF;
          img {
            display: none!important;
          }
        }
      }
      .copyright {
        font-size: 0.18rem;
      }
    }
    &.light {
      .homepage-footer {
        &__hd {
          border-top: solid 1px #EEEEEE;
          > span {
            background-color: #fff;
          }
        }
        &__ft {
          .link-group {
            .link-item {
              color: #666666;
            }
          }
        }
      }
    }
  }
}