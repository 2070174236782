* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.homepage {
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 100px;

  a {
    text-decoration: none;
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 0.5rem;
  width: 0.46rem;
  height: 0.46rem;
  z-index: 99;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

@media (max-width: 768px) {
  .scroll-to-top {
    width: 1rem;
    height: 1rem;
  }
  .homepage {
    padding-bottom: 1rem;
  }
}

// @media (max-width: 1200px) and (min-width: 768px) {
//   .homepage {
//     width: 1200px;
//   }
// }
