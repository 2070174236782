$image-basic-path: '../static/images/gan356i-branch2/';

@mixin cover-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin contain-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 769px) {
  .scene-gan356i {
    .section {
      height: 9rem;
      position: relative;
      overflow: hidden;
    }
    .background {
      height: 100%;
    }
    .element {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
    .text-block {
      text-align: center;
      top: 0.7rem;
      color: #333;
      h1 {
        font-size: 0.45rem;
        margin-bottom: 0.34rem;
        font-weight: normal;

        .colorful {
          background-image: -webkit-linear-gradient(left,#4FAEFF, #F065A6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      p {
        font-size: 0.18rem;
        line-height: 2em;
      }
    }
    .section-1 {
      .background {
        @include cover-background('section-1__bg.png');
      }
    }
    .section-2 {
      .background {
        @include cover-background('section-2__bg.png');
      }
      .text-block {
        color: #fff;
        h1 {
          font-size: 0.4rem;
          .colorful {
            font-size: 0.48rem;
          }
        }
      }
    }
    .section-3 {
      .background {
        @include cover-background('section-3__bg.png');
      }
      .text-block {
        color: #fff;
        h1 {
          font-size: 0.4rem;
          .colorful {
            font-size: 0.48rem;
          }
        }
      }
    }
    .section-4 {
      .background {
        @include cover-background('section-4__bg.png');
      }
      .text-block {
        color: #fff;
        h1 {
          font-size: 0.4rem;
          .colorful {
            font-size: 0.48rem;
          }
        }
      }
    }
    .section-5 {
      .background {
        @include cover-background('section-5__bg.png');
      }
    }
    .section-6 {
      .background {
        @include cover-background('section-6__bg.png');
      }
      .text-block {
        color: #fff;
      }
    }
    .section-7 {
      .background {
        @include cover-background('section-7__bg.png');
      }
      .text-block {
        color: #fff;
      }
    }
    .section-8 {
      .background {
        @include cover-background('section-8__bg.png');
      }
    }
  }

  .en {
    .scene-gan356i {
      .text-block {
        top: 0.4rem;
      }
      .section-1 {
        .background {
          @include cover-background(('section-1__bg-en.png'))
        }
      }
      .section-2 {
        .background {
          @include cover-background(('section-2__bg-en.png'))
        }
      }
      .section-4 {
        .background {
          @include cover-background(('section-4__bg-en.png'))
        }
      }
      .section-6 {
        .background {
          @include cover-background(('section-6__bg-en.png'))
        }
      }
      .section-7 {
        .background {
          @include cover-background(('section-7__bg-en.png'))
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .scene-gan356i {
    .section {
      height: 4.3rem;
      .text-block {
        display: none;
      }
      .background {
        height: 100%;
      }
    }
    .section-1 {
      height: 6.1rem;
      .background {
        @include cover-background('mb-section-1__preview.png');
      }
    }
    .section-2 {
      .background {
        @include cover-background('mb-section-2__preview.png');
      }
    }
    .section-3 {
      .background {
        @include cover-background('mb-section-3__preview.png');
      }
    }
    .section-4 {
      .background {
        @include cover-background('mb-section-4__preview.png');
      }
    }
    .section-5 {
      .background {
        @include cover-background('mb-section-5__preview.png');
      }
    }
    .section-6 {
      .background {
        @include cover-background('mb-section-6__preview.png');
      }
    }
    .section-7 {
      .background {
        @include cover-background('mb-section-7__preview.png');
      }
    }
    .section-8 {
      .background {
        @include cover-background('mb-section-8__preview.png');
      }
    }
  }
  .en {
    .scene-gan356i {
      .section-1 {
        .background {
          @include cover-background(('mb-section-1__preview-en.png'))
        }
      }
      .section-2 {
        .background {
          @include cover-background(('mb-section-2__preview-en.png'))
        }
      }
      .section-3 {
        .background {
          @include cover-background(('mb-section-3__preview-en.png'))
        }
      }
      .section-4 {
        .background {
          @include cover-background(('mb-section-4__preview-en.png'))
        }
      }
      .section-5 {
        .background {
          @include cover-background(('mb-section-5__preview-en.png'))
        }
      }
      .section-6 {
        .background {
          @include cover-background(('mb-section-6__preview-en.png'))
        }
      }
      .section-7 {
        .background {
          @include cover-background(('mb-section-7__preview-en.png'))
        }
      }
      .section-8 {
        .background {
          @include cover-background(('mb-section-8__preview-en.png'))
        }
      }
    }
  }
}