$image-basic-path: '../static/images/gan-robot/';

@mixin cover-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin contain-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 769px) {
  .scene-gan-robot {
    background-color: rgb(229, 226, 232);
    .section {
      position: relative;
    }
    .element {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
    .text-block {
      color: #333333;
      z-index: 2;
      h1 {
        font-weight: normal;    
      }
      p {
        font-size: 0.18rem;
        line-height: 0.28rem;
      }
    }
    .background {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .section-1 {
      height: 10.38rem;
      .background {
        @include cover-background('section-1__bg.png');
        // @include cover-background('section-1__preview.png');
      }
      .text-block {
        top: 6.4rem;
        width: 4rem;
        right: 12rem;
        left: 3.8rem;
        p {
          font-size: 0.2rem;
          line-height: 1.5em;
        }
        .large {
          font-size: 0.6rem;
          color: #170F1E;
          font-weight: bold;
        }
      }
    }
    .section-2 {
      height: 19.85rem;
      .background {
        // @include cover-background('section-2__preview.png');
        @include cover-background('section-2__bg.png');
      }
      .text-block {
        width: 6.8rem;
        left: -3.3rem;
        top: 0.9rem;
        h1 {
          font-size: 0.44rem;
          line-height: 0.6rem;
          margin-bottom: 0.4rem;
          position: relative;
          span {
            color: #910081;
          }
          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            left: -0.45rem;
            width: 0.2rem;
            background-color: #FEA200;
          }         
        }
      }
    }
    .section-3 {
      height: 9.6rem;
      .background {
        // @include cover-background('section-3__preview.png');
        @include cover-background('section-3__bg.png');
      }
      .text-block {
        width: 3.6rem;
        top: 3.1rem;
        left: -5.2rem;
        h1 {
          font-size: 0.3rem;
          margin-bottom: 0.3rem;
          font-weight: normal;
        }
        span {
          font-size: 0.45rem;
          font-weight: bold;
        }
      }
    }
    .section-4 {
      height: 8.3rem;
      .background {
        @include cover-background('section-4__bg.png');
        // @include cover-background('section-4__preview.png');
      }
      .text-block {
        width: 3.6rem;
        left: -5.3rem;
        top: 1rem;
        h1 {
          font-size: 0.3rem;
          margin-bottom: 0.3rem;
          font-weight: normal;
        }
        span {
          font-size: 0.45rem;
          font-weight: bold;
        }
      }
    }
    .section-5 {
      height: 10.6rem;
      .background {
        @include cover-background('section-5__bg.png');
        // @include cover-background('section-5__preview.png');
      }
      .text-block {
        width: 3.6rem;
        left: -5.3rem;
        top: 0.9rem;    
        h1 {
          font-size: 0.3rem;
          margin-bottom: 0.3rem;
          font-weight: normal;
        }
        span {
          font-size: 0.45rem;
          font-weight: bold;
        }
      }
    }
    .section-6 {
      height: 6.44rem;
      .background {
        @include cover-background('section-6__bg.png');
        // @include cover-background('section-6__preview.png');
      }
      .title {
        color: #fff;
        top: 1.1rem;
        font-size: 0.22rem;
        right: 4.8rem;
        width: 3.6rem;    
      }
      .scene {
        color: #fff;
        top: 4.6rem;    
        width: 2.6rem;
        h1 {
          font-size: 0.25rem;
          margin-bottom: 0.24rem;
        }
        p {
          font-size: 0.15rem;
        }
      }
      .scene-1 {
        left: -6.3rem;
      }
      .scene-2 {
        left: -0.3rem;
      }
      .scene-3 {
        left: 5.8rem;
      }
    }
    .section-7 {
      height: 5.09rem;
      .background {
        @include cover-background('section-7__bg.png');
        // @include cover-background('section-7__preview.png');
      }
      .text-block {
        color: #fff;
        width: 5.2rem;
        left: -3.7rem;
        top: 0.8rem;
        .subtitle {
          font-size: 0.2rem;
          color: #02EEFF;
        }
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-8 {
      height: 11.96rem;
      .background {
        @include cover-background('section-8__bg.png');
        // @include cover-background('section-8__preview.png');
      }
      .text-block {
        color: #fff;
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-9 {
      height: 11.58rem;
      .background {
        @include cover-background('section-9__bg.png');
        // @include cover-background('section-9__preview.png');
      }
      .text-block {
        color: #fff;
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-10 {
      height: 10.44rem;
      .background {
        @include cover-background('section-10__bg.png');
        // @include cover-background('section-10__preview.png');
      }
      .text-block {
        color: #fff;
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-11 {
      height: 5.11rem;
      .background {
        @include cover-background('section-11__preview.png');
      }
    }
    .section-12 {
      height: 10.44rem;
      .background {
        @include cover-background('section-12__bg.png');
        // @include cover-background('section-12__preview.png');
      }
      .text-block {
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-13 {
      height: 9.5rem;
      .background {
        @include cover-background('section-13__bg.png');
        // @include cover-background('section-12__preview.png');
      }
      .text-block {
        color: #fff;
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-14 {
      height: 4.9rem;
      .background {
        @include cover-background('section-14__preview.png');
      }
    }
    .section-15 {
      height: 10.02rem;
      .background {
        @include cover-background('section-15__bg.png');
        // @include cover-background('section-15__preview.png');
      }
      .text-block {
        color: #fff;
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
        }
      }
    }
    .section-16 {
      height: 10.44rem;
      .background {
        @include cover-background('section-16__bg.png');
        // @include cover-background('section-16__preview.png');
      }
      .text-block {
        color: #fff;
        text-align: center;
        top: 0.8rem;
        h1 {
          font-size: 0.45rem;
          margin-bottom: 0.3rem;
          position: relative;
  
          &:after {
            content: '';
            display: block;
            height: 0.06rem;
            width: 0.5rem;
            background-color: #FF3C00;
            position: absolute;
            top: 0.95rem;
            left: 50%;
            margin-left: -0.25rem;
          }
        }
      }
    }
  }
  .en {
    .scene-gan-robot {
      .section-1 {
        .text-block {
          width: 5.4rem;
          left: 3.9rem;
          .large {
            font-size: 0.36rem;
            font-weight: normal;
          }
          p {
            font-size: 0.2rem;
          }
        }
      }
      .section-2 {
        .background {
          @include cover-background('section-2__bg-en.png')
        }
      }
      .section-3 {
        .text-block {
          width: 5rem;
          top: 3.1rem;
          left: -3.9rem;        
        }
      }
      .section-4 {
        .text-block {
          width: 5.6rem;
          left: -3.2rem;
          top: 1rem;     
        }
      }
      .section-5 {
        .text-block {
          width: 5.6rem;
          left: -3.2rem;
          top: 1rem;
        }
      }
      .section-6 {
        .title {
          right: -0.2rem;
          width: 8.6rem;
        }
        .scene {
          width: 2.7rem;
          h1 {
            font-size: 0.2rem;
          }
        }
        .scene-3 {
          left: 6.6rem;
          width: 3.4rem;
        }
      }
      .section-7 {
        .background {
          @include cover-background('section-7__bg-en.png');
        }
        .text-block {
          h1 {
            font-size: 0.35rem;
          }
        }
      }
      .section-10 {
        .background {
          @include cover-background('section-10__bg-en.png');
        }
      }
      .section-11 {
        .background {
          @include cover-background('section-11__bg-en.png');
        }
      }
      .section-12 {
        .background {
          @include cover-background('section-12__bg-en.png');
        }
      }
      .section-13 {
        .background {
          @include cover-background('section-13__bg-en.png');
        }
      }
      .section-14 {
        .background {
          @include cover-background('section-14__bg-en.png');
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .scene-gan-robot {
    .text-block {
      display: none;
    }
    .section {
      position: relative;
    }
    .element {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
    .background {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .section-1 {
      height: 14.6rem;
      .background {
        @include cover-background('mb-section-1__preview.png');
      }
    }
    .section-2 {
      height: 14.72rem;
      .background {
        @include cover-background('mb-section-2__preview.png');
      }
    }
    .section-3 {
      height: 16.9rem;
      .background {
        @include cover-background('mb-section-3__preview.png');
      }
    }
    .section-4 {
      height: 18.37rem;
      .background {
        @include cover-background('mb-section-4__preview.png');
      }
    }
    .section-5 {
      height: 5.8rem;
      .background {
        @include cover-background('mb-section-5__preview.png');
      }
    }
    .section-6 {
      height: 5.08rem;
      .title,
      .scene {
        display: none;
      }
      .background {
        @include cover-background('mb-section-6__preview.png');
      }
    }
    .section-7 {
      height: 11.96rem;
      .background {
        @include cover-background('mb-section-7__preview.png');
      }
    }
    .section-8 {
      height: 11.59rem;
      .background {
        @include cover-background('mb-section-8__preview.png');
      }
    }
    .section-9 {
      height: 10.44rem;
      .background {
        @include cover-background('mb-section-9__preview.png');
      }
    }
    .section-10 {
      height: 5.11rem;
      .background {
        @include cover-background('mb-section-10__preview.png');
      }
    }
    .section-11 {
      height: 10.83rem;
      .background {
        @include cover-background('mb-section-11__preview.png');
      }
    }
    .section-12 {
      height: 9.5rem;
      .background {
        @include cover-background('mb-section-12__preview.png');
      }
    }
    .section-13 {
      height: 4.9rem;
      .background {
        @include cover-background('mb-section-13__preview.png');
      }
    }
    .section-14 {
      height: 10.03rem;
      .background {
        @include cover-background('mb-section-14__preview.png');
      }
    }
    .section-15 {
      height: 10.43rem;
      .background {
        @include cover-background('mb-section-15__preview.png');
      }
    }
  }

  .en {
    .scene-gan-robot {
      .section-1 {
        .background {
          @include cover-background(('mb-section-1__preview-en.png'));
        }
      }
      .section-2 {
        .background {
          @include cover-background(('mb-section-2__preview-en.png'));
        }
      }
      .section-3 {
        .background {
          @include cover-background(('mb-section-3__preview-en.png'));
        }
      }
      .section-4 {
        .background {
          @include cover-background(('mb-section-4__preview-en.png'));
        }
      }
      .section-5 {
        .background {
          @include cover-background(('mb-section-5__preview-en.png'));
        }
      }
      .section-6 {
        .background {
          @include cover-background(('mb-section-6__preview-en.png'));
        }
      }
      .section-7 {
        .background {
          @include cover-background(('mb-section-7__preview-en.png'));
        }
      }
      .section-8 {
        .background {
          @include cover-background(('mb-section-8__preview-en.png'));
        }
      }
      .section-9 {
        .background {
          @include cover-background(('mb-section-9__preview-en.png'));
        }
      }
      .section-10 {
        .background {
          @include cover-background(('mb-section-10__preview-en.png'));
        }
      }
      .section-11 {
        .background {
          @include cover-background(('mb-section-11__preview-en.png'));
        }
      }
      .section-12 {
        .background {
          @include cover-background(('mb-section-12__preview-en.png'));
        }
      }
      .section-13 {
        .background {
          @include cover-background(('mb-section-13__preview-en.png'));
        }
      }
      .section-14 {
        .background {
          @include cover-background(('mb-section-14__preview-en.png'));
        }
      }
      .section-15 {
        .background {
          @include cover-background(('mb-section-15__preview-en.png'));
        }
      }
    }
  }
}