$image-basic-path: '../static/images/gan356i/';

@mixin cover-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin contain-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-width: 769px) {
  .gan-356i {
    // global
    .section {
      width: 19.2rem;
      height: 9.84rem;
      position: relative;
      overflow: hidden;
    }
    .element {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
    .text-block {
      color: #fff;
      z-index: 10;
      h1 {
        font-size: 0.5rem;
        margin-bottom: .15rem;
        margin-top: 0;
        font-weight: normal;
        line-height: 1.3em;
        color: inherit !important;
        > span {
          font-size: inherit !important;
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.3rem;
        line-height: 1.5em;
        color: inherit !important;
      }
    }
  
    &.zh {
      .text-block {
        color: #fff;
        z-index: 10;
        h1 {
          font-size: 0.5rem;
          margin-bottom: .15rem;
          margin-top: 0;
          font-weight: normal;
          line-height: 1.3em;
        }
        p {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 0.22rem;      
          line-height: 1.5em;
        }
      }
    }
  
    .section-1 {
      // @include cover-background('section-1__preview.png');
      @include cover-background('section-1__bg.png');
      .product {
        @include contain-background('section-1__product.png');
        height: 9.84rem;
        top: 0;
      }
      .content {
        width: 13.28rem;
        text-align: center;
        top: .4rem;
  
        h1 > span {
          background-image:-webkit-linear-gradient(right,#6fc5ff,#b1ade4,#f487c0); 
          -webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;
        }
      }
    }
  
    .section-2 {
      height: 7.99rem;
      // @include cover-background('section-2__preview.png');
      @include cover-background('section-2__bg.png');
      // background-size: 100% auto;
      background-attachment: fixed;
      .content {
        width: 11.38rem;
        text-align: center;
        top: 0.7rem;
      }
      .product {
        @include contain-background('section-2__product.png');
        height: 8rem;
        width: 19.2rem;
        bottom: 0;
        left: -4rem;
        right: -4rem;
      }
    }
  
    .section-3 {
      // @include cover-background('section-3__preview.png');
      @include cover-background('section-3__bg.png');
      height: 9rem;
      overflow: hidden;
      .content {
        width: 7.6rem;
        text-align: center;
        top: 0.7rem;
      }
      .product {
        @include contain-background('section-3__product.png');
        height: 12.26rem;
        top: -2.2rem;
      }
    }
  
    .section-4 {
      height: 9.2rem;
      background: linear-gradient(bottom, #fff, #f2f0fa);
      // @include cover-background('section-4__preview.png');
      .chart {
        @include contain-background('section-4__chart.png');
        height: 4.7rem;
        top: 2.6rem;
        left: -0.5rem;
      }
      .info {
        @include contain-background('section-4__info.png');
        height: 1.15rem;
        top: 7.4rem;
        left: 0.7rem;
      }
      .content {
        color: #333;
        width: 13.6rem;
        text-align: center;
        top: 0.1rem;
      }
    }
  
    &.zh {
      .section-4 {
        .content {
          top: 0.7rem;
        }
        .info {
          @include contain-background('section-4__info-zh.png');
        }
      }
    }
  
    .section-5 {
      height: 9rem;
      // @include cover-background('section-5__preview.png');
      @include cover-background('section-5__bg.png');
      .product {
        @include contain-background('section-5__product.png');
        height: 7.2rem;
        width: 10.16rem;
        right: 0;
        left: auto;
        bottom: 0;
      }
      .content {
        color: #333;
        width: 7.5rem;
        left: -8.2rem;
        top: 2.4rem;
      }
    }
    .section-6 {
      height: 8rem;
      @include cover-background('section-6__bg.png');
      .product {
        @include contain-background('section-6__product.png');
        height: 5.36rem;
        top: 1.7rem;
        left: -6.6rem;    
      }
      .content {
        color: #333;
        width: 8.4rem;
        top: 3rem;
        left: 9.3rem;    
      }
    }
    .section-7 {
      height: 8rem;
      @include cover-background('section-7__bg.png');
      .product {
        @include contain-background('section-7__product.png');
        height: 7.73rem;
        width: 6.58rem;
        bottom: 0;
        left: 6.9rem;    
      }
      .content {
        width: 7.79rem;
        top: 1.3rem;
        left: -6.1rem;
      }
    }
  
    &.zh {
      .section-8 {
        .content {
          top: 1.2rem;
        }
      }
    }
  
    .section-8 {
      height: 8rem;
      @include cover-background('section-8__bg.png');
      .product {
        @include contain-background('section-8__product.png');
        height: 5.6rem;
        bottom: -1.2rem;
      }
      .content {
        width: 13.67rem;
        top: 0.5rem;
        text-align: center;
  
        h1 > span {
          background-image:-webkit-linear-gradient(right,#f487c0,#b1ade4,#6fc5ff); 
          -webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;
        }
      }
    }
  
    &.zh {
      .section-9 {
        .content {
          top: 1rem;
        }
      }
    }
  
    .section-9 {
      height: 8rem;
      @include cover-background('section-9__bg.png');
      .product {
        @include contain-background('section-9__product.png');
        height: 4.4rem;
        bottom: 0.3rem;
        left: 0.7rem;    
      }
      .content {
        color: #333;
        width: 13.2rem;
        top: 0.4rem;
        text-align: center; 
      }
    }
    .section-10 {
      height: 8rem;
      @include cover-background('section-10__bg.png');
  
      .content {
        width: 7rem;
        left: -8.1rem;
        top: 1.1rem;
      }
    }
    .section-11 {
      height: 8rem;
      @include cover-background('section-11__bg.png');
      .content {
        color: #333;
        width: 8.05rem;
        left: 8.7rem;
        top: 2.2rem;    
      }
    }
    .section-12 {
      height: 8rem;
      @include cover-background('section-12__bg.png');
  
      .content {
        text-align: center;
        top: 0.6rem;
      }
    }
  
    &.zh {
      .section-12 {
        .content {
          top: 1.2rem;
        }
      }
    }
  
    .section-13 {
      height: 9rem;
      background-color: #fefefe;
      .title {
        top: 0.5rem;
        text-align: center;
        width: 6rem;
        h1 {
          font-size: 0.6rem;
          margin-bottom: 0;
          background-image: -webkit-linear-gradient(left,#4faeff,#ab84cc,#ef65a7);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 1.3em;
        }
        h2 {
          font-size: 0.7rem;
          font-weight: normal;
          margin-top: 0;
          color: #333;
          line-height: 1.5em;
        }
      }
      .product-intro {
        width: 4.4rem;
        text-align: center;
        z-index: 10;
        color: #333;
        margin-top: 0.2rem;
        h4 {
          font-size: 0.3rem;
          margin-bottom: 0;
          font-weight: normal;
          margin-top: 0;
        }
        h3 {
          margin-top: 0.1rem;
          font-size: 0.4rem;
          font-weight: normal;
        }
      }
      .product-1 {
        top: 2.7rem;
        left: -5.8rem;
      }
      .product-2 {
        top: 2.7rem;
        right: -6.3rem;
      }
      .product-1-pic {
        width: 7.1rem;
        left: -4.2rem;
        top: 2.3rem;
      }
      .product-2-pic {
        width: 7.1rem;
        right: -7.5rem;
        top: 2.3rem;    
      }
    }
  
    &.zh {
      .section-13 {
        .content {
          top: 1rem;
        }
        .product-intro {
          width: 3.8rem;
          text-align: center;
          z-index: 10;
          color: #333;
          h4 {
            font-size: 0.2rem;
            margin-bottom: 0;
            font-weight: normal;
          }
          h3 {
            margin-top: 0.1rem;
            font-size: 0.3rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .gan-356i {
    padding-top: 56px;
    .section {
      width: 7.5rem;
      height: 10.5rem;
      position: relative;
      overflow: hidden;
    }
    .text-block {
      color: #fff;
      z-index: 10;
      padding: 0 0.3rem;
      text-align: center;
      h1 {
        font-size: 0.38rem;
        margin-bottom: 0.1rem;      
        margin-top: 0;
        font-weight: normal;
        line-height: 1.2em;
        color: inherit !important;
        > span {
          font-size: inherit !important;
        }
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.3rem;
        line-height: 1.3em;
        color: inherit !important;
      }
    }
    .element {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
    .section-1 {
      height: 11.85rem;
      @include cover-background('mb-section-1__bg.png');
      .product {
        width: 100%;
        height: 100%;
        @include contain-background('mb-section-1__product.png');
      }
      .content {
        top: 0.3rem;

        h1 {
          font-size: 0.5rem;

          > span {
            color: #fff;
          }
        }

        // h1 > span {
        //   background-image:-webkit-linear-gradient(right,#6fc5ff,#b1ade4,#f487c0); 
        //   -webkit-background-clip: text; 
        //   -webkit-text-fill-color: transparent;
        // }
      }
    }
    .section-2 {
      height: 10.5rem;
      @include cover-background('section-2__bg.png');
      background-attachment: local;
      .content {
        top: 0.7rem;
      }
      .product {
        @include contain-background('mb-section-2__product.png');
        bottom: -0.8rem;
        height: 8.99rem;
        left: -0.5rem;
      }
    }
    &.zh {
      .section-2 {
        .content {
          top: 1.2rem;
        }
      }
    }
  
    .section-3 {
      @include cover-background('section-3__bg.png');
      overflow: hidden;
      .content {
        text-align: center;
        top: 0.7rem;
      }
      .product {
        @include contain-background('mb-section-3__product.png');
        height: 9.74rem;
        top: 1rem;      
      }
    }
  
    .section-4 {
      // background: linear-gradient(bottom, #fff, #f2f0fa);
      // @include contain-background('mb-section-4__preview.png');
      .chart {
        @include contain-background('mb-section-4__chart.png');
        height: 4.5rem;
        top: 3.6rem;
        left: -0.2rem;
      }
      .info {
        @include contain-background('section-4__info.png');
        height: 1rem;
        top: 8.4rem;      
        left: 0.1rem;
      }
      .content {
        color: #333;
        text-align: center;
        top: 0.4rem;

        p {
          word-break: break-all;
        }
      }
    }
    &.zh {
      .section-4 {
        .content {
          top: 1.2rem;
        }
        .info {
          @include contain-background('section-4__info-zh.png');
        }
      }
    }
    .section-5 {
      @include cover-background('section-5__bg.png');
      .product {
        @include contain-background('section-5__product.png');
        height: 5.7rem;
        width: 8.56rem;
        right: 0;
        left: -0.1rem;
        bottom: 0rem;
      }
      .content {
        color: #333;
        top: 0.6rem;
        h1 {
          font-size: 0.38rem;
        }
      }
    }
    &.zh {
      .section-5 {
        .content {
          color: #333;
          top: 1.0rem;
        }
      }
    }
    .section-6 {
      .product {
        @include contain-background('section-6__product.png');
        height: 5.96rem;
        top: 3.9rem;
        left: 0rem;
      }
      .content {
        color: #333;
        top: 0.8rem;
      }
    }
    .section-7 {
      @include cover-background('section-7__bg.png');
      .product {
        @include contain-background('section-7__product.png');
        height: 7.73rem;
        width: 6.58rem;
        bottom: 0;
      }
      .content {
        top: 0.6rem;
      }
    }
    &.zh {
      .section-6,
      .section-7,
      .section-8,
      .section-9,
      .section-10,
      .section-11,
      .section-12 {
        .content {
          top: 1.2rem;
        }
      }
    }
  
    .section-8 {
      @include cover-background('section-8__bg.png');
      .product {
        @include contain-background('section-8__product.png');
        height: 5.6rem;
        bottom: -0.2rem;
        width: 9.5rem;
        left: -0.6rem;      
      }
      .content {
        top: 0.5rem;
        text-align: center;
  
        h1 > span {
          background-image:-webkit-linear-gradient(right,#f487c0,#b1ade4,#6fc5ff); 
          -webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .section-9 {
      @include cover-background('section-9__bg.png');
      .product {
        @include contain-background('section-9__product.png');
        height: 4.4rem;
        width: 8.3rem;
        bottom: 1.1rem;
        left: -0.1rem;
      }
      .content {
        color: #333;
        top: 0.8rem;
        text-align: center;
      }
    }
    .section-10 {
      height: 10.65rem;
      @include cover-background('mb-section-10__bg.png');
  
      .content {
        top: 0.4rem;

        p {
          font-size: 0.25rem;
        }
      }
    }
    .section-11 {
      @include cover-background('mb-section-11__bg.png');
      .content {
        color: #333;
        top: 0.4rem;    
      }
    }
    .section-12 {
      height: 9rem;
      @include cover-background('mb-section-12__bg.png');
      .content {
        top: 0.2rem;    
      }
    }
    .section-12 {
      height: 8.5rem;
      @include cover-background('mb-section-12__bg.png');
      .content {
        top: 0.3rem;    
      }
    }
    .section-13 {
      height: 8rem;
      .title {
        top: 0.5rem;
        text-align: center;
        width: 6rem;
        h1 {
          font-size: 0.6rem;
          margin-bottom: 0;
          background-image: -webkit-linear-gradient(left,#4faeff,#ab84cc,#ef65a7);
          -webkit-background-clip: text; 
          -webkit-text-fill-color: transparent;
        }
        h2 {
          font-size: 0.7rem;
          font-weight: normal;
          margin-top: 0;
          color: #333;
        }
      }
      .product-intro {
        width: 4.4rem;
        text-align: center;
        z-index: 10;
        color: #333;
        h4 {
          font-size: 0.3rem;
          margin-bottom: 0;
          font-weight: normal;
        }
        h3 {
          margin-top: 0.1rem;
          font-size: 0.4rem;
          font-weight: normal;
        }
      }
      .product-1 {
        top: 2.5rem;
        left: -3.6rem;      
      }
      .product-2 {
        top: 2.5rem;
        right: -3.7rem;
      }
      .product-1-pic {
        width: 5.1rem;
        left: -2.6rem;
        top: 3.5rem;
      }
      .product-2-pic {
        width: 5.1rem;
        right: -4.7rem;
        top: 3.5rem;
      }
    }
    &.zh {
      .section-13 {
        .product-intro {
          width: 3.8rem;
          text-align: center;
          z-index: 10;
          color: #333;
          h4 {
            font-size: 0.2rem;
            margin-bottom: 0;
            font-weight: normal;
          }
          h3 {
            margin-top: 0.1rem;
            font-size: 0.24rem;
            font-weight: normal;
          }
        }
      }
    }
  }
}
