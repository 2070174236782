// Font=================================================================================================
$font: Tahoma, Arial, "Microsoft YaHei", sans-serif !default;
$western-font: Georgia, Cambria, "Times New Roman", Times, serif !default;

$font-size: 14px !default;
$small-font-size: 12px !default;
$h1-size: 24px !default;
$h2-size: 20px !default;
$h3-size: 18px !default;
$h4-size: 16px !default;

// Color================================================================================================
$text-color: #979798 !default;
$text-dark-color: #323232 !default;
$body-color: #f5f5f5 !default;

$white: #fff;
$red: #eb6165;
$gray: #fbfaf9;
$dark-gray: #f7f6f6;
//other=================================================================================================
$content-width: 1200px;

// Mixin================================================================================================
@mixin inline-block($alignment: middle) {
  display: inline-block;
  @if $alignment and $alignment != none {
    vertical-align: $alignment;
  }
  zoom: 1;
  * + html & {
    display: inline;
  }
}
