.cubestation-view {
  .showcase {
    height: 500px;
    &__container {
      width: 1100px;
      height: 100%;
      margin: 0 auto;
    }
    &__content-wrapper {
      display: flex;
      flex-direction: row;
      height: 100%;
      &.reverse {
        flex-direction: row-reverse;
        .showcase__intro-side {
          justify-content: flex-end;
        }
      }
    }
    &__intro-side {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
    }
    &__text-block {
      h2 {
        font-size: 44px;
        color: #333;
        font-weight: normal;
        padding-bottom: 30px;
        margin-bottom: 30px;
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 30px;
          height: 3px;
          background-color: #67b6ff;
          position: absolute;
          bottom: 0;
        }
      }
      p {
        color: #848484;
        font-size: 18px;
      }
    }
    &__image-side {
      position: relative;
      height: 100%;
      .picture {
        width: 100%;
        height: auto;
      }
    }
  }
}

.showcase-1 {
  .showcase {
    &__image-side {
      width: 723px;
    }
  }
  .picture {
    position: absolute;
    bottom: 0;
  }
}

.showcase-2 {
  .showcase {
    &__image-side {
      width: 558px;
      display: flex;
      align-items: center;
    }
  }
}

.showcase-3 {
  .showcase {
    &__image-side {
      width: 423px;
      display: flex;
      align-items: center;
    }
  }
  .picture {
    position: relative;
    right: 73px;
  }
}

.showcase-4 {
  .showcase {
    &__image-side {
      width: 500px;
      display: flex;
      align-items: center;
    }
  }
}

.showcase-5 {
  .showcase {
    &__image-side {
      width: 340px;
    }
  }
  .picture {
    position: absolute;
    bottom: 0;
    right: 150px;
  }
}

.showcase-2,
.showcase-4 {
  background-color: #f9f9f9;
}

.cubestation-footer {
  background-color: #002044;
  padding-top: 60px;
  .mb.inner {
    display: none;
  }
  > .inner {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: solid 1px #334253;
  }

  .slogan {
    height: 68px;
    margin-bottom: 60px;
  }

  .download {
    display: flex;
  }

  .download-box {
    width: 180px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #808993;
    border-radius: 4px;
    margin: 0 25px;

    .inner {
      text-align: center;
      color: #fff;
      font-size: 14px;
      img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 15px;
      }
    }
  }
}

$image-basic-path: '../static/images/cubestation/';

@mixin inner-background ($url) {
  background-image: url(#{$image-basic-path}#{$url});
  background-size: 6.9rem 3.9rem;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 769px) {
  .cubestation-view {
    padding: 0.15rem 0;
    .showcase {
      height: 4.2rem;
      &__intro-side {
        display: none;
      }
      &__image-side {
        display: none;
      }
    }
  }
  .showcase-1 {
    @include inner-background('mb-section-1__preview.png');
  }
  .showcase-2 {
    @include inner-background('mb-section-2__preview.png');
  }
  .showcase-3 {
    @include inner-background('mb-section-3__preview.png');
  }
  .showcase-4 {
    @include inner-background('mb-section-4__preview.png');
  }
  .showcase-5 {
    @include inner-background('mb-section-5__preview.png');
  }

  .en {
    .showcase-1 {
      @include inner-background('mb-section-1__preview-en.png');
    }
    .showcase-2 {
      @include inner-background('mb-section-2__preview-en.png');
    }
    .showcase-3 {
      @include inner-background('mb-section-3__preview-en.png');
    }
    .showcase-4 {
      @include inner-background('mb-section-4__preview-en.png');
    }
    .showcase-5 {
      @include inner-background('mb-section-5__preview-en.png');
    }
  }

  .cubestation-footer {
    .pc.inner {
      display: none;
    }
    .mb.inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #fff;
      border-bottom: none;
      .qrcode-wrapper {
        width: 200px;
        height: 200px;
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 14px;
        img {
          width: 100%;
        }
      }
      .qrcode-prompt {
        font-size: 0.26rem;
        margin-bottom: 0.4rem;
      }
      .btn {
        width: 3.50rem;
        height: 0.88rem;
        background: linear-gradient(180deg,rgba(103,182,255,1) 0%,rgba(22,124,231,1) 100%);
        border-radius: 0.44rem;
        line-height: 0.88rem;
        text-align: center;
        font-size: 0.3rem;
      }
    }
  }
}